import { useContentBlock, useOnResize } from "@src/Hooks";
import { IconChevronRight } from "@tabler/icons-react";
import axios from "axios";
import { ClickScrollPlugin, OverlayScrollbars } from "overlayscrollbars";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { useContext, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ServerUrl,
  abbrevScenario,
  calculateTestingProgress,
  defaults,
  getActivityName,
  getModuleName,
  getScenarioName,
  getSubset,
  getTaskName,
  isLastActivityInTrainingModule,
  nextTest,
  nextTraining,
  pause,
  prevTraining,
} from "../Utils";
import FeedbackModal from "../components/FeedbackModal";
import { ActivityAM, ActivityHA } from "../components/ScenarioActivities";
import { useScenarioIntro } from "../components/ScenarioIntro";
import {
  LeftSidebar,
  RightSidebar,
  ScenarioFooter,
} from "../components/ScenarioSidebars";
import { DisplayInfo, ScenarioInfo } from "../contexts/Contexts";
import "../styles/scenario.css";

function Scenario() {
  const location = useLocation();
  const navigate = useNavigate();
  const { states, setStates, actions } = useContext(ScenarioInfo);
  const { isMobile, isCompact } = useContext(DisplayInfo);

  Object.assign(actions, { toggleMobileFooter });

  const {
    topView,
    topViewUpNext,
    frameOpacity,
    activityState,
    isCompleted,
    hasCorrectAnswer,
    responses,
    scenarioWindow,
    isFeedbackOpen,
    isDriverView,
    mirrorView,
    showQuestion,
    noGlanceMade,
  } = states;

  const {
    setTopView,
    setTopViewUpNext,
    setPlaying,
    setFrameOpacity,
    setAvatarVariant,
    setActivityState,
    setIsCompleted,
    setHasCorrectAnswer,
    setCurrInstructions,
    setResponses,
    setSectionedProgressData,
    setContinuousProgressBarData,
    setIsFeedbackOpen,
    setIsDriverView,
    setMirrorDisabled,
    setShowQuestion,
    setPerformReset,
  } = setStates;

  const [wideMobile, setWideMobile] = useState(!isCompact);
  const [HAPracticeAnswer, setHAPracticeAnswer] = useState(false);
  const [AMPracticeAnswer, setAMPracticeAnswer] = useState(false);
  const [nextBtnDisabled, setNextBtnDisabled] = useState(false);
  const [retryActivity, setRetryActivity] = useState(false);
  const [practiceText, setPracticeText] = useState("");
  const [checkedHADriverView, setCheckedHADriverView] = useState(false);
  const [isGlanceCompletedForPractice, setIsGlanceCompletedForPractice] =
    useState(false);
  const [renderMobileProgress, setRenderMobileProgress] = useState(false);
  const [showMobileFooter, setShowMobileFooter] = useState(false);
  const [showEndAlert, setShowEndAlert] = useState(false);
  // const [showTimer, setShowTimer] = useState(false);
  const mobileProgressTimeout = useRef(null);
  const leftSidebarRef = useRef(null);
  const rightSidebarRef = useRef(null);
  const sidebarBps = useRef({ leftBp: 0, rightBp: 0 });
  const headerRef = useRef(null);
  const navigatingRef = useRef(null);

  const tests = ["preTest", "postTest", "postDrivingTest"];
  OverlayScrollbars.plugin(ClickScrollPlugin);

  document.body.id = "scenario";
  document.body.classList.add("frame-loading");
  if (frameOpacity === 1) {
    document.body.classList.remove("frame-loading");
  }
  // TODO: ??
  if (!activityState.practiceMode && activityState.taskId === "") {
  }

  const {
    isIntro,
    introInstruction,
    introContinue,
    addIntroTarget,
    introProgressData,
    checkIsIntro,
    contentPortal: introContentPortal,
  } = useScenarioIntro();

  const introContinueTippy = {
    className: "intro-info",
    content: (
      <ul>
        You can now continue to the practice. Please click Next when you are
        ready.
      </ul>
    ),
    hideAfter: 5000,
    appendTo: document.body,
    // TODO: doesn't show immediately
    onAfterUpdate: (inst) => inst.show(),
  };

  // TODO: load the test data - actual module id, scenario id,
  // activity id and asset module id, scenario id, activity id

  useContentBlock(() => {
    if (!scenarioWindow) return;
    const container = scenarioWindow.parentNode;
    const windowHeight = scenarioWindow.clientHeight;
    const containerHeight = container.clientHeight;
    const smallWindow = 2 * windowHeight < containerHeight;
    return smallWindow;
  }, [scenarioWindow]);

  useEffect(() => {
    if (frameOpacity && !isMobile && showMobileFooter) {
      toggleMobileFooter();
    }
  }, [frameOpacity, isMobile, showMobileFooter]);

  useEffect(() => {
    // const showTimer =
    //   tests.includes(activityState.taskId) &&
    //   (isIntro || !activityState.practiceMode);
    // setShowTimer(showTimer);

    if (isIntro) setCurrInstructions(introInstruction);
  }, [isIntro, frameOpacity, activityState]);

  useOnResize(() => initScenario(scenarioWindow), [scenarioWindow]);
  useEffect(() => initScenario(scenarioWindow), [scenarioWindow]);

  useOnResize(() => {
    if (!scenarioWindow) return;
    pause(10).then(refreshSidebarBreakpoints);
    pause(200).then(refreshSidebarBreakpoints);
  }, [scenarioWindow, isMobile, isCompact]);

  useEffect(() => {
    if (!frameOpacity || !scenarioWindow) return;
    pause(10).then(refreshSidebarBreakpoints);
    pause(200).then(refreshSidebarBreakpoints);
  }, [scenarioWindow, frameOpacity, isMobile, isCompact]);

  // TODO: add practice instructions (use practiceMode)
  useEffect(() => {
    const { stopReadInstructions } = actions;
    window.onbeforeunload = stopReadInstructions;

    if (activityState.done === true) {
      navigate("/dashboard"); // end of task, navigate to dashboard and fetch new userInfo (postDrivingTestActivated might be true)
    }

    if (activityState.taskId === "preTest") {
      document.onvisibilitychange = () => {
        // page hidden or minimized
      };
      setHAPracticeAnswer(false);
      setAMPracticeAnswer(false);
    }

    return () => stopReadInstructions?.();
  }, [activityState]);

  useEffect(() => {
    // TODO: handle location.state is not empty but incomplete {security}
    if (!location.state) {
      navigate("/dashboard");
    }
  }, [location.state]);

  useEffect(() => {
    if (!activityState.moduleId) return;

    // progress bar data not required for practice mode
    if (!isIntro && !activityState.practiceMode) {
      fetchProgressBarData();
    }
    if (!isIntro) {
      fetchActivityStatus();
    }
    setAvatarVariant(
      tests.includes(activityState.taskId) && !activityState.practiceMode
        ? "default"
        : responses.last === "incorrect"
          ? "incorrect"
          : "default",
    );
  }, [activityState, responses]);

  useEffect(() => {
    checkNextDisabled();
  }, [
    topView,
    topViewUpNext,
    activityState,
    isCompleted,
    hasCorrectAnswer,
    HAPracticeAnswer,
    AMPracticeAnswer,
    responses,
    checkedHADriverView,
    isGlanceCompletedForPractice,
  ]);

  function fetchActivityStatus() {
    const pid = `users/${location.state.participantId}`;
    const tid = `tasks/${activityState.taskId}`;
    const mid = `modules/${activityState.moduleId}`;
    const sid = `scenarios/${activityState.scenarioId}`;
    const aid = `activities/${activityState.activityId}`;
    const type = activityState.type;

    if (tid && pid && mid && sid && aid) {
      axios
        .get(`${ServerUrl}/${pid}/${tid}/${mid}/${sid}/${aid}`, {
          withCredentials: true,
        })
        .then((res) => {
          setIsCompleted(res.data.data.isCompleted);
          setHasCorrectAnswer(res.data.data.hasCorrectAnswer);
        })
        .catch((err) => {
          console.log(err);
          /* handle error */
        });
    }
  }

  async function fetchProgressBarData() {
    const tid = activityState.taskId;
    const mid = activityState.moduleId;
    const sid = activityState.scenarioId;
    const aid = activityState.activityId;
    const practiceMode = activityState.practiceMode;
    const pid = location.state.participantId;
    const progressType = tid === "training" ? "sectioned" : "continuous";

    if (progressType === "continuous") {
      setContinuousProgressBarData(
        calculateTestingProgress(
          sid,
          aid,
          practiceMode,
          false,
          // location.state.role === "VALIDATION_STUDY",
        ).modulePercentage,
      );
    } else {
      if (tid && mid && pid) {
        await axios
          .get(`${ServerUrl}/users/${pid}/tasks/${tid}/modules/${mid}/status`, {
            withCredentials: true,
          })
          .then((res) => {
            setSectionedProgressData(res.data.data);
          })
          .catch((err) => {
            console.log(err);
            const isHA = activityState.type === "HA";
            tests.includes(tid)
              ? setContinuousProgressBarData(0)
              : setSectionedProgressData(Array(isHA ? 12 : 6).fill(null));
          });
      }
    }
  }

  function initScenario(scenarioWindow) {
    if (!scenarioWindow) return;
    const container = scenarioWindow.parentNode;
    const aspectRatioRaw = window.getComputedStyle(scenarioWindow).aspectRatio;
    if (!aspectRatioRaw) return;
    const aspectRatio = parseFloat(aspectRatioRaw.toString());

    const setMax = () => {
      const scaledWidth = parseInt(container.offsetHeight * aspectRatio);
      container.style.maxWidth = `${scaledWidth}px`;
    };
    if (container.offsetHeight < 1) {
      pause(10).then(setMax);
    } else {
      setMax();
    }
    if (!frameOpacity) {
      pause(200).then(() => setFrameOpacity(1));
    }
  }

  // function onTimerEnd() {
  //   setShowEndAlert(true);
  //   const pid = location.state.participantId;
  //   const tid = activityState.taskId;
  //   axios
  //     .post(
  //       `${ServerUrl}/users/${pid}/tasks/${tid}/complete-task`,
  //       {},
  //       {
  //         withCredentials: true,
  //       },
  //     )
  //     .catch((err) => console.log(err));
  // }

  async function handleNavigate(event) {
    if (navigatingRef.current) return;
    const navDone = () => {
      pause(500).then(() => (navigatingRef.current = false));
    };

    navigatingRef.current = true;
    const btnClass = event.target.className;
    const currActivity = { ...activityState };
    let newActivity = null;
    if (isIntro && introContinue) {
      navigate(0);
      return navDone();
    }

    if (btnClass.includes("nav-prev")) {
      newActivity =
        currActivity.taskId === "training"
          ? prevTraining(currActivity)
          : currActivity;
    } else if (btnClass.includes("nav-next")) {
      if (topViewUpNext) {
        setShowQuestion(false); // to remove rvm grid from player window after correct glance
        setTopView(true);
        setTopViewUpNext(false);
        setPlaying(true);
        return navDone();
      }

      if (
        !retryActivity &&
        activityState.practiceMode &&
        activityState.type === "HA" &&
        !HAPracticeAnswer
      ) {
        setHAPracticeAnswer(true);
        return navDone();
      }

      if (
        !retryActivity &&
        activityState.practiceMode &&
        activityState.type === "AM" &&
        !AMPracticeAnswer
      ) {
        setAMPracticeAnswer(true);
        return navDone();
      }

      // add proxy for hasCorrectAnswer (should not go back to dashboard if hasCorrectAnswer is true) ?
      if (!retryActivity && isLastActivityInTrainingModule(currActivity)) {
        navigate("/dashboard", {
          state: getSubset(location.state, defaults.dashboardState),
        });
        return navDone();
      }

      newActivity = retryActivity
        ? currActivity
        : tests.includes(currActivity.taskId)
          ? nextTest(currActivity, location.state.activityOrder)
          : nextTraining(currActivity, activityState.practiceMode);

      setRetryActivity(false);

      if (newActivity) {
        checkIsIntro(
          newActivity.type,
          newActivity.taskId === "preTest",
          newActivity.practiceMode,
        );
      }
    }
    // TODO: better soln for this
    if (newActivity === null) {
      navigate("/dashboard"); // end of task, navigate to dashboard and fetch new userInfo (postDrivingTestActivated might be true)
    } else {
      if (activityState.type === "HA" && isDriverView) {
        setIsDriverView(false);
      } else if (activityState.type === "AM" && (mirrorView || showQuestion)) {
        setMirrorDisabled(true);
        await pause(500);
      }

      setActivityState(newActivity);
      document.onvisibilitychange = null;
      // we still need this navigate call to keep the state on refresh
      navigate("/scenario", {
        state: {
          ...location.state,
          ...newActivity,
        },
        replace: true,
      });
      setResponses({ ...defaults.responseHistory });
    }
    navDone();
  }

  function resizeHeader() {
    if (!headerRef.current) return;
    const header = headerRef.current;
    const title = header.childNodes[0];
    const timer = header.childNodes[1];
    header.classList.add("resize-flex");
    if (!timer) return;
    const offset = timer.clientWidth;
    title.style.maxWidth = `calc(100% - 1rem - ${offset}px)`;
  }

  function calcWideMobile() {
    if (!isMobile) return true;
    if (isCompact) return false;

    const frame = scenarioWindow.parentElement.parentElement;
    const leftBar = leftSidebarRef.current;
    const rightBar = rightSidebarRef.current;
    if (!frame || !leftBar || !rightBar) return;
    const frameRect = frame.getBoundingClientRect();
    const leftBarRect = leftBar.getBoundingClientRect();
    const rightBarRect = rightBar.getBoundingClientRect();

    // calculate available sidebar margin
    const marginLeft = parseInt(leftBarRect.left - frameRect.left);
    const marginRight = parseInt(frameRect.right - rightBarRect.right);
    const { leftBp, rightBp } = sidebarBps.current;
    const offset = wideMobile ? 20 : 30;
    const leftSpace = leftBarRect.width + marginLeft > leftBp + offset;
    const rightSpace = rightBarRect.width + marginRight > rightBp + offset;
    const comfyMargins = marginLeft > offset && marginRight > offset;

    return leftSpace && rightSpace && comfyMargins;
  }

  function refreshSidebarBreakpoints() {
    const newWideMobile = calcWideMobile();

    setWideMobile((wideMobile) => {
      if (isMobile && wideMobile !== newWideMobile) {
        document.body.classList.add("wide-mobile-change");
        pause(300).then(() => {
          document.body.classList.remove("wide-mobile-change");
        });
      }
      return newWideMobile;
    });
    if (!document.body.classList.contains("wide-mobile-checked")) {
      document.body.classList.add("wide-mobile-checked");
    }

    if (!isMobile) return;

    /** @type {HTMLElement} */
    const leftBar = leftSidebarRef.current;
    /** @type {HTMLElement} */
    const rightBar = rightSidebarRef.current;
    if (!leftBar || !rightBar) return;

    // keep max width of sidebars
    let { leftBp, rightBp } = sidebarBps.current;
    leftBp = Math.max(leftBp, leftBar.clientWidth);
    rightBp = Math.max(rightBp, rightBar.clientWidth);

    if (newWideMobile) {
      // handle outliers maxxing out breakpoint
      if (leftBp > leftBar.clientWidth) leftBp = leftBar.clientWidth;
      if (rightBp > rightBar.clientWidth) rightBp = rightBar.clientWidth;
    }

    sidebarBps.current = { leftBp, rightBp };
    setWideMobile(calcWideMobile());
  }

  function toggleMobileFooter(callback) {
    clearTimeout(mobileProgressTimeout.current);

    if (showMobileFooter) {
      // hide from view, then un-render
      setShowMobileFooter(false);
      mobileProgressTimeout.current = setTimeout(() => {
        setRenderMobileProgress(false);
        callback?.();
      }, 250);
    } else {
      // render, then bring into view
      setRenderMobileProgress(true);
      mobileProgressTimeout.current = setTimeout(() => {
        setShowMobileFooter(true);
      }, 5);
    }
  }

  function handleExit() {
    document.onvisibilitychange = null;
    navigate("/dashboard", {
      state: getSubset(location.state, defaults.dashboardState),
    });
  }

  function checkNextDisabled() {
    if (!location.state) return;

    if (location.state.role === "RESEARCHER") {
      setNextBtnDisabled(false);
      return;
    }

    if (tests.includes(activityState.taskId) && !activityState.practiceMode) {
      setNextBtnDisabled(!isCompleted);
    } else if (
      activityState.type === "AM" &&
      (topViewUpNext || topView || noGlanceMade)
    ) {
      setNextBtnDisabled(false);
      setRetryActivity(
        (activityState.taskId === "training" || activityState.practiceMode) &&
          (topView || noGlanceMade) &&
          (!isCompleted || !hasCorrectAnswer),
      );
    } else if (activityState.practiceMode) {
      const completeAndCorrect = isCompleted && responses.last === "correct";

      const isDisabledForHA =
        !checkedHADriverView || (HAPracticeAnswer && !completeAndCorrect);

      const isDisabledForAM =
        !isGlanceCompletedForPractice ||
        (AMPracticeAnswer && !completeAndCorrect);

      setNextBtnDisabled(
        activityState.type === "HA" ? isDisabledForHA : isDisabledForAM,
      );

      // TODO: added because topView is not set to false initially and so retryActivity is not set to false (when incorrect attempt followed by correct attempt for AM)
      setRetryActivity((topView || noGlanceMade) && !completeAndCorrect);
    } else if (activityState.taskId === "training") {
      const incompleteOrNeverCorrect = !isCompleted || !hasCorrectAnswer;

      // for HA, retry activity if 3rd incorrect attempt
      const isThirdIncorrectAttemptForHA =
        activityState.type === "HA" &&
        responses.correct < 1 && // not required
        responses.incorrect >= 3;

      // for AM, retry activity if incorrect attempt
      const isIncorrectAttemptForAM =
        activityState.type === "AM" &&
        responses.correct < 1 && // not required
        responses.incorrect >= 1;

      setNextBtnDisabled(
        incompleteOrNeverCorrect &&
          !(isIncorrectAttemptForAM || isThirdIncorrectAttemptForHA),
      );
      setRetryActivity(
        incompleteOrNeverCorrect &&
          (isIncorrectAttemptForAM || isThirdIncorrectAttemptForHA),
      );
    }
  }

  return (
    location.state && (
      <OverlayScrollbarsComponent
        className="scroll-container"
        options={{
          scrollbars: {
            clickScroll: true,
          },
        }}
        style={{
          opacity: frameOpacity,
          transition: "100ms ease opacity",
        }}
      >
        <Helmet>
          <title>
            {isIntro
              ? "Getting Started"
              : activityState.practiceMode
                ? activityState.type + " - Practice"
                : getModuleName(activityState.moduleId) +
                  " - " +
                  abbrevScenario(activityState.scenarioId)}
          </title>
        </Helmet>
        {/* TODO: ?? */}
        {isIntro && frameOpacity && introContentPortal}
        <header className="main" ref={headerRef}>
          <span className="title">
            <span className="module">
              {tests.includes(activityState.taskId)
                ? getTaskName(activityState.taskId)
                : getModuleName(activityState.moduleId)}
            </span>
            <IconChevronRight stroke={2.5} />
            <span className="scenario">
              {!isIntro && activityState.practiceMode && (
                <>
                  Practice
                  <span className="bul">{" \u2022 "}</span>
                </>
              )}
              {getScenarioName(activityState.scenarioId)}

              {activityState.type === "HA" && (
                <>
                  <span className="bul">{" \u2022 "}</span>
                  {getActivityName(activityState.activityId)}
                </>
              )}
            </span>
          </span>
          {/* {!isMobile && showTimer && (
            <Timer
              continued
              minutes={60}
              startOn={frameOpacity}
              onEnd={onTimerEnd}
              disabled={isCompact || contentBlocked}
              inactive={isIntro}
              ref={addIntroTarget}
              testType={activityState.taskId}
              participantId={location.state && location.state.participantId}
            />
          )} */}
        </header>
        <div
          className="scenario-frame"
          style={{ pointerEvents: showEndAlert && "none" }}
        >
          <LeftSidebar
            isIntro={isIntro}
            wideMobile={wideMobile}
            addIntroTarget={addIntroTarget}
            handleNavigate={handleNavigate}
            handleExit={handleExit}
            ref={leftSidebarRef}
          />
          {activityState.type === "HA" ? (
            <ActivityHA
              practiceAnswer={HAPracticeAnswer}
              setCheckedDriverView={setCheckedHADriverView}
              ref={addIntroTarget}
            />
          ) : (
            <ActivityAM
              practiceAnswer={AMPracticeAnswer}
              setPracticeText={setPracticeText}
              setIsGlanceCompletedForPractice={setIsGlanceCompletedForPractice}
              ref={addIntroTarget}
            />
          )}
          <RightSidebar
            // showTimer={showTimer}
            wideMobile={wideMobile}
            isNextButtonDisabled={nextBtnDisabled}
            addIntroTarget={addIntroTarget}
            // onTimerEnd={onTimerEnd}
            handleNavigate={handleNavigate}
            introContinue={introContinue}
            introContinueTippy={introContinueTippy}
            isIntro={isIntro}
            ref={rightSidebarRef}
          />
        </div>
        <ScenarioFooter
          isIntro={isIntro}
          addIntroTarget={addIntroTarget}
          nextBtnDisabled={nextBtnDisabled}
          practiceText={practiceText}
          introProgressData={introProgressData}
          introContinue={introContinue}
          introContinueTippy={introContinueTippy}
          renderMobileProgress={renderMobileProgress}
          showMobileFooter={showMobileFooter}
          handleNavigate={handleNavigate}
          handleExit={handleExit}
        />
        <FeedbackModal
          modalState={[isFeedbackOpen, setIsFeedbackOpen]}
          withCredentials
        />
        {/* <TimerEndModal
          state={[showEndAlert, setShowEndAlert]}
          onConfirm={() => navigate("/dashboard")} // end of task, navigate to dashboard and fetch new userInfo (postDrivingTestActivated might be true)
        /> */}
      </OverlayScrollbarsComponent>
    )
  );
}

export default Scenario;
