import { pause } from "@src/Utils";
import { IconChevronDown } from "@tabler/icons-react";
import clsx from "clsx";
import { useEffect, useState } from "react";
import Card from "./Card";
import CheckBox from "./CheckBox";
import StudyIndicator from "./StudyIndicator";

const formatter = new Intl.DateTimeFormat("en-US", {
  timeZone: "America/Los_Angeles",
  day: "numeric",
  month: "short",
  year: "numeric",
});

/**
 * @typedef {{
 *   participantId: string | number;
 *   fullName: string;
 *   email: string;
 *   dateJoined: string;
 *   preTest: string;
 *   training: string;
 *   postTest: string;
 *   postDrivingTest: string;
 *   progress: number;
 *   lastActivity: string;
 *   study: string;
 *   role: string;
 * }} UserCardInfo
 *
 * @param {{
 *   user: UserCardInfo;
 *   selected: boolean;
 *   visible: boolean;
 *   onToggleSelected: function;
 *   hideOnDeselect: boolean;
 * }} props
 */
const UserListCard = (props) => {
  const { user, selected, visible, onToggleSelected, hideOnDeselect } = props;

  /** @type {UseState<UserCardInfo>} */
  const [parsedUser, setParsedUser] = useState(null);
  const [expanded, setExpanded] = useState(false);

  const deselected = hideOnDeselect && !selected;

  useEffect(() => {
    setExpanded(false);
  }, [user]);

  useEffect(() => {
    if (!visible) return;
    if (user) setParsedUser({ ...user });
  }, [user, visible]);

  useEffect(() => {
    if (deselected) {
      setParsedUser(null);
      setExpanded(false);
    } else if (hideOnDeselect) {
      pause(100).then(() => setParsedUser({ ...user }));
    }
  }, [selected]);

  /**
   * @param {MouseEvent} event
   * @param {any} user
   * @param {boolean} selected
   */
  function onToggleCheck(event, user, selected) {
    event.stopPropagation();
    onToggleSelected?.(user, selected);
  }

  function colorTask(task) {
    return task === "Not Activated" ? "red" : task && "green";
  }

  return (
    <Card
      className={clsx(
        "user-list-card",
        deselected && "hidden",
        !parsedUser && "loading",
        expanded && "expanded",
      )}
    >
      <div
        className="user-data-header"
        onClick={() => setExpanded(!expanded)}
        style={{ opacity: visible ? 1 : 0 }}
        inert={deselected ? "" : undefined}
      >
        {visible && (
          <>
            <CheckBox
              className="select-check"
              state={[selected, (e) => onToggleCheck(e, user, selected)]}
              variant="dark"
            />
            <span className="user-name">{parsedUser?.fullName}</span>
            <span className="user-pid">{parsedUser?.participantId}</span>
            <span className="user-study">
              <StudyIndicator role={parsedUser?.role} />
            </span>
            <span className="user-progress">{parsedUser?.progress}</span>
            <IconChevronDown
              className={clsx("expand-arrow", expanded && "open")}
              size="2rem"
              stroke={2.4}
            />
          </>
        )}
      </div>
      <div className="expanded-info">
        {expanded && (
          <div className="wrapper">
            <InfoRow k="Name" v={parsedUser?.fullName} />
            <InfoRow k="Email" v={parsedUser?.email} />
            <InfoRow k="Study" v={parsedUser?.study} />
            <InfoRow k="Joined on" v={parsedUser?.dateJoined} />

            <InfoRow
              k="Training"
              v={parsedUser?.training || "N/A"}
              className={colorTask(parsedUser?.training)}
            />
            <InfoRow
              k="Quiz 1"
              v={parsedUser?.preTest || "N/A"}
              className={colorTask(parsedUser?.preTest)}
            />
            <InfoRow
              k="Quiz 2"
              v={parsedUser?.postTest || "N/A"}
              className={colorTask(parsedUser?.postTest)}
            />
            <InfoRow
              k="Quiz 3"
              v={parsedUser?.postDrivingTest || "N/A"}
              className={colorTask(parsedUser?.postDrivingTest)}
            />
          </div>
        )}
      </div>
    </Card>
  );
};

/**
 * @param {{
 *   someSelected: boolean;
 *   allSelected: boolean;
 *   onSelectAll: Function;
 *   onDeselectAll: Function;
 *   selectDisabled: boolean;
 * }} props
 */
export const UserListHeader = (props) => {
  const {
    someSelected,
    allSelected,
    onSelectAll,
    onDeselectAll,
    selectDisabled,
  } = props;

  const [shiftDown, setShiftDown] = useState(false);
  const [selectHovered, setSelectHovered] = useState(false);

  window.onkeydown = (event) => setShiftDown(event.shiftKey);
  window.onkeyup = (event) => setShiftDown(event.shiftKey);

  return (
    <Card
      className={clsx("user-list-header")}
      bgColor="var(--list-header-color)"
    >
      <div className="user-data-header">
        <CheckBox
          className="select-check"
          state={[someSelected, someSelected ? onDeselectAll : onSelectAll]}
          icon={allSelected ? "check" : shiftDown ? "plus" : "minus"}
          variant="dark"
          disabled={selectDisabled}
        />
        <span className="user-name">Full Name</span>
        <span className="user-pid">Participant ID</span>
        <span className="user-study">Study</span>
        <span className="user-progress">Progress</span>
        <IconChevronDown
          className="expand-arrow"
          size="2rem"
          visibility="hidden"
        />
      </div>
    </Card>
  );
};

/** @param {{ k: string; v: string; className: string }} */
const InfoRow = ({ k, v, className }) => (
  <div className={clsx("info-row", className)}>
    {k && <span className="info-key">{k}:</span>}
    <span className="info-value">{`${v}`}</span>
  </div>
);

export default UserListCard;
