import { createRef, useContext, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { Helmet } from "react-helmet-async";
import { keyEnter, siteKey } from "../Utils";
import { Button } from "../components/Button";
import FeedbackModal from "../components/FeedbackModal";
import LoginModal from "../components/LoginModal";
import RegisterModal from "../components/RegisterModal";
import { DisplayInfo } from "../contexts/Contexts";
import "../styles/home.css";

function Home() {
  const urlParams = new URLSearchParams(window.location.search);
  const recaptchaRef = createRef();
  const [recaptchaToken, setRecaptchaToken] = useState(null);

  const { isTablet, isPortrait } = useContext(DisplayInfo);

  const [isRegisterOpen, setIsRegisterOpen] = useState(false);
  const [isLoginOpen, setIsLoginOpen] = useState(false);

  const [isFeedbackOpen, setIsFeedbackOpen] = useState(false);

  const [stacked, setStacked] = useState(isTablet || isPortrait);

  const linkText = "Report Issue | Help";
  const buttons = [
    <Button
      key={1}
      id="register-button"
      label="Register"
      variant="secondary"
      fontSize={stacked ? "1.6rem" : "1.4rem"}
      onClick={() => setIsRegisterOpen(true)}
    />,
    <Button
      key={2}
      id="sign-in-button"
      label="Sign in"
      variant="light"
      fontSize={stacked ? "1.6rem" : "1.4rem"}
      onClick={() => setIsLoginOpen(true)}
    />,
  ];

  document.body.id = "home";

  useEffect(() => {
    const encodedToken = urlParams.get("token");
    if (encodedToken !== null) setIsRegisterOpen(true);
  }, []);

  useEffect(() => {
    setStacked(isTablet || isPortrait);
  }, [isTablet, isPortrait]);

  return (
    <>
      <Helmet>
        <title>Welcome | Hazard Perception</title>
      </Helmet>
      <header>Hazard Perception</header>
      <div className="button-group">
        {stacked ? buttons.reverse() : buttons}

        <a
          className="link"
          type="button"
          tabIndex={0}
          onClick={() => setIsFeedbackOpen(true)}
          onKeyDown={(e) => keyEnter(e, () => setIsFeedbackOpen(true))}
        >
          {linkText}
        </a>
      </div>

      <RegisterModal state={[isRegisterOpen, setIsRegisterOpen]} />
      <LoginModal
        state={[isLoginOpen, setIsLoginOpen]}
        recaptchaRef={recaptchaRef}
      />
      <FeedbackModal modalState={[isFeedbackOpen, setIsFeedbackOpen]} />

      {createPortal(
        <ReCAPTCHA ref={recaptchaRef} sitekey={siteKey} size="invisible" />,
        document.body,
      )}
    </>
  );
}

export default Home;
