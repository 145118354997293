import CheckBox from "./CheckBox";

/**
 * @param {{
 *   selected: boolean;
 *   onChange: ButtonProps["onClick"];
 * } & Omit<React.ComponentProps<CheckBox>, "radio" | "state">} props
 */
const RadioButton = (props) => {
  const { selected, onChange, ...otherProps } = props;

  return <CheckBox radio state={[selected, onChange]} {...otherProps} />;
};

export default RadioButton;
