import { useStateRef } from "@src/Hooks";
import { IconLoader2, IconThumbUp } from "@tabler/icons-react";
import axios from "axios";
import { domToBlob } from "modern-screenshot";
import workerUrl from "modern-screenshot/worker?url";
import { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { ServerUrl, getEntrance, pause } from "../Utils";
import { ModalContext, ScenarioInfo } from "../contexts/Contexts";
import "../styles/feedback-form.css";
import { Button, setLoading, shake, stopLoading } from "./Button";
import CheckBox from "./CheckBox";
import InputField, { SelectField } from "./InputField";
import { ScrollToError, swapContent } from "./Modal";

/**
 * @param {{
 *   withCredentials: boolean;
 *   modalState: UseState<boolean>;
 *   formOpenState: UseState<boolean>;
 *   entry: "Scenario" | "Profile" | "Home";
 * }} props
 */
const FeedbackForm = (props) => {
  const { withCredentials = false, modalState, formOpenState, entry } = props;

  const BUG_REPORT = "Bug Report";
  const SUGGESTION = "Suggestion";
  const SUPPORT = "Support";
  const OTHER = "Other";
  const CAPTURE_HEIGHT_PX = 720;

  const categories = [BUG_REPORT, SUGGESTION, SUPPORT, OTHER];
  const descriptionHints = {};
  descriptionHints[BUG_REPORT] = [
    "Please describe the issue here.",
    "Provide as much detail as possible,",
    "however make sure not to include any",
    "personal info or sensitive data.",
  ].join(" ");
  descriptionHints[SUGGESTION] = "Tell us what's on your mind...";
  descriptionHints[SUPPORT] = "Let us know how we can help...";
  descriptionHints[OTHER] = "Please describe your concern...";

  const defaultState = {
    form: { category: BUG_REPORT, description: "" },
    error: { category: "", description: "" },
  };
  const [success, setSuccess] = useState(false);
  const [modalOpen, setModalOpen] = modalState;
  const [formOpen, setFormOpen] = formOpenState;

  // form input and errors for username, password
  const [feedbackForm, setFeedbackForm] = useState({ ...defaultState.form });
  const [feedbackErr, setFeedbackErr] = useState({ ...defaultState.error });

  // bug report state
  const [includeCapture, setIncludeCapture] = useState(false);
  const [screenCapture, setScreenCapture] = useState(null);
  const [captureSize, setCaptureSize] = useState({ width: 0, height: 0 });

  /** @type {UseState<DebugInfo>} */
  const [debugInfo, setDebugInfo] = useState(null);
  const [isCapturing, _setter, capturingRef] = useStateRef(false);
  const captureUrl = screenCapture ? URL.createObjectURL(screenCapture) : null;

  const { contentElement } = useContext(ModalContext);
  const scenarioInfo = useContext(ScenarioInfo);
  const inScenario = Object.keys(scenarioInfo).length > 0;

  /** @type {ButtonTippyProps} */
  const captureTippy = {
    className: "capture-tooltip",
    disabled: !screenCapture,
    content: (
      <>
        Capture Preview
        <br />({captureSize.width} x {captureSize.height})
      </>
    ),
    placement: "bottom",
  };

  useEffect(() => {
    if (!formOpen) {
      setFeedbackForm({ ...defaultState.form });
      setFeedbackErr({ ...defaultState.error });
      setSuccess(false);
    } else {
      collectDebugInfo();
      takeScreenshot();
    }
  }, [formOpen]);

  function formInput(event) {
    const input = event.target;
    if (input) {
      setFeedbackForm({
        ...feedbackForm,
        [input.id]: input.value,
      });
    }
  }

  function collectDebugInfo() {
    if (!inScenario) return;

    // prettier-ignore
    const {
      avatarVariant,
      instructionJSON,
      scenarioWindow,
      isFeedbackOpen,
      frameOpacity,
      ...relevantState
    } = scenarioInfo.states;

    setDebugInfo(relevantState);
  }

  function takeScreenshot() {
    if (capturingRef.current) return;
    capturingRef.current = true;
    setScreenCapture(null);

    const { scenarioWindow } = scenarioInfo.states || {};
    const video = scenarioWindow?.querySelector("video");
    const stillFrame = video && captureStill(video);

    buildCapture().then(({ blob, width, height }) => {
      if (stillFrame) {
        video.parentNode.removeChild(stillFrame);
      }
      setScreenCapture(blob);
      setIncludeCapture(true);
      setCaptureSize({ width, height });
      capturingRef.current = false;
    });
  }

  /** @param {HTMLVideoElement} video */
  function captureStill(video) {
    if (!video) return null;

    const { clientWidth, clientHeight, videoWidth, videoHeight } = video;

    const visibleAspectRatio = clientWidth / clientHeight;
    const actualAspectRatio = videoWidth / videoHeight;
    let realWidth = clientWidth;
    let realHeight = clientHeight;

    if (actualAspectRatio > visibleAspectRatio) {
      realWidth = clientHeight * actualAspectRatio;
    } else {
      realHeight = clientWidth / actualAspectRatio;
    }

    const stillFrame = document.createElement("canvas");
    stillFrame.classList.add("still-frame", ...video.classList);
    stillFrame.width = realWidth;
    stillFrame.height = realHeight;
    stillFrame.getContext("2d").drawImage(video, 0, 0, realWidth, realHeight);

    return video.parentNode.appendChild(stillFrame);
  }

  async function buildCapture() {
    const html = document.documentElement;
    const mult = CAPTURE_HEIGHT_PX / html.clientHeight;
    const pixelRatio = Math.min(mult, 1);
    const width = Math.round(html.clientWidth * pixelRatio);
    const height = Math.round(html.clientHeight * pixelRatio);

    const notFeedbackOrProfile = (node) => {
      const isPortal = node?.classList?.contains?.("ReactModalPortal");
      const containsFeedback = !!node?.querySelector?.("#feedback-modal");
      const containsProfile = !!node?.querySelector?.(".profile-modal");
      return !(isPortal && (containsFeedback || containsProfile));
    };
    const notVideo = (node) => node?.nodeName !== "VIDEO";

    await pause(300);
    const blob = await domToBlob(document.documentElement, {
      workerUrl,
      features: true,
      scale: pixelRatio,
      filter: (node) => notFeedbackOrProfile(node) && notVideo(node),
    });

    return { blob, width, height };
  }

  async function handleCancel() {
    if (feedbackForm.description) {
      setFeedbackForm({ ...defaultState.form });
      setFeedbackErr({ ...defaultState.error });
      await pause(200);
    }
    const modalClass = contentElement.classList;
    if (modalClass.contains("feedback-form")) {
      setModalOpen(false);
    } else {
      swapContent(contentElement, async () => {
        await pause(150);
        setFormOpen(false);
        await pause(100);
      });
    }
  }

  async function handleSuccess() {
    setFeedbackErr({ ...defaultState.error });
    await swapContent(contentElement, async () => {
      await pause(150);
      setSuccess(true);
      await pause(100);
    });
    await pause(2500);
    handleCancel();
  }

  async function submitFeedback(event) {
    let validForm = true;
    let errors = { ...defaultState.error };
    const btn = event.target;

    if (!feedbackForm.category) {
      errors.category = "Required field missing";
      validForm = false;
    }

    setFeedbackErr(errors);
    if (!validForm) {
      stopLoading(btn);
      shake(btn);
      return;
    }

    const { activityState } = scenarioInfo.states || {};
    const { taskId, moduleId, scenarioId, activityId } = activityState || {};

    /** @type {FeedbackContext} */
    const context = {
      ...feedbackForm,
      entrance: getEntrance(entry, activityState),
      isProfileEntry: entry === "Profile",
      taskId,
      moduleId,
      scenarioId,
      activityId,
    };

    let sentCapture = null;
    let sentDebugInfo = null;

    if (includeCapture) {
      sentCapture = screenCapture;
    }
    if (inScenario) {
      sentDebugInfo = JSON.stringify(debugInfo);
    }

    const feedbackData = new FormData();
    feedbackData.append("screenCapture", sentCapture);
    feedbackData.append("debugInfo", sentDebugInfo);
    feedbackData.append("context", JSON.stringify(context));
    setLoading(btn);

    await axios
      .post(`${ServerUrl}/feedback/new`, feedbackData, {
        withCredentials,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(() => {
        handleSuccess();
      })
      .catch((err) => {
        console.log(err);
        // check network error
        if (err.code === "ERR_NETWORK") {
          setFeedbackErr({
            ...defaultState.form,
            email: "Feedback submission failed due to network error",
          });
          validForm = false;
          return;
        }
        const res = err.response;
        setFeedbackErr({
          ...defaultState.error,
          [res.data.data]: res.data.message,
        });
        validForm = false;
      });
    if (!validForm) {
      stopLoading(btn);
      shake(btn);
    }
  }

  return (
    formOpen &&
    (success ? (
      <div className="feedback-form success">
        <div className="feedback-message">
          <header>Thank You</header>
          <p>We've received your request, and will get back to you soon.</p>
          <IconThumbUp stroke={1.2} />
        </div>
      </div>
    ) : (
      <>
        <Helmet>
          <title>Feedback | Hazard Perception</title>
        </Helmet>
        <ScrollToError deps={[feedbackErr]} />
        <div className="feedback-form">
          <div className="feedback-message">
            <header>How can we help?</header>
            <p>
              Share your question or feedback with our support team to help
              improve our app.
            </p>
          </div>
          <div className="input-col">
            <SelectField
              id="category"
              label="Category"
              value={feedbackForm.category}
              options={categories}
              onChange={formInput}
              error={feedbackErr.category}
            />
            <div className="bug-report-opts">
              <CheckBox
                className="screen-cap"
                label="Include Screen Capture"
                state={[
                  includeCapture,
                  () => setIncludeCapture(!includeCapture),
                ]}
                disabled={!screenCapture}
                info={
                  <>
                    This screen capture will help us more quickly resolve the
                    issue you are experiencing. The capture only includes the
                    content of the Hazard Perception app and does not include
                    other details like open tabs or areas outside the Hazard
                    Perception app.
                  </>
                }
                after={
                  isCapturing || !screenCapture ? (
                    <IconLoader2 className="loading-capture" />
                  ) : (
                    <Button
                      className="capture-thumb"
                      depth="0px"
                      bgColor="var(--main-bg-color)"
                      icon={<img src={captureUrl} />}
                      tippy={captureTippy}
                    />
                  )
                }
              />
            </div>
            <InputField
              id="description"
              label="Feedback"
              error={feedbackErr.description}
              value={feedbackForm.description}
              placeholder={descriptionHints[feedbackForm.category]}
              onChange={formInput}
              textArea
            />
            <span className="button-row">
              <Button
                label="Cancel"
                className="btn-cancel"
                variant="secondary"
                onClick={handleCancel}
              />
              <Button
                type="submit"
                label="Submit"
                onClick={submitFeedback}
                styles={{ width: "100%" }}
              />
            </span>
          </div>
        </div>
      </>
    ))
  );
};

export default FeedbackForm;
