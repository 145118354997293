import ResetPwModal from "@src/components/ResetPwExpiredModal";
import axios from "axios";
import { ClickScrollPlugin, OverlayScrollbars } from "overlayscrollbars";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import {
  ServerUrl,
  defaults,
  emailFilters,
  encryptWithPublicKey,
  pause,
} from "../Utils";
import { Button, setLoading, shake, stopLoading } from "../components/Button";
import InputField from "../components/InputField";
import { ScrollToError } from "../components/Modal";
import "../styles/reset-password.css";

function ResetPassword() {
  const navigate = useNavigate();

  const [verifyLoading, setVerifyLoading] = useState(true);
  const [expiredLinkOpen, setExpiredLinkOpen] = useState(false);
  const [invalidLinkOpen, setInvalidLinkOpen] = useState(false);
  const [resetPwForm, setResetPwForm] = useState({ ...defaults.resetPassword });
  const [resetPwErr, setResetPwErr] = useState({ ...defaults.resetPassword });

  const urlParams = new URLSearchParams(window.location.search);
  const encodedToken = urlParams.get("token");
  const resetDisabled = !verifyLoading && (!encodedToken || !resetPwForm.email);

  OverlayScrollbars.plugin(ClickScrollPlugin);
  document.body.id = "reset-password";

  useEffect(() => {
    if (encodedToken) {
      autofill();
    } else {
      pause(200).then(() => setVerifyLoading(false));
    }
  }, []);

  function formInput(event) {
    const input = event.target;
    if (input) {
      setResetPwForm({
        ...resetPwForm,
        [input.id]: input.value.trim(),
      });
    }
  }

  function autofill() {
    axios
      .post(`${ServerUrl}/users/reset-password/verify-url`, {
        token: encodedToken,
      })
      .then((res) => {
        const email = res.data.data;
        setResetPwForm({
          ...defaults.resetPassword,
          email: email,
        });
      })
      .catch((err) => {
        if (err.code === "ERR_NETWORK") {
          setResetPwErr({
            ...defaults.resetPassword,
            email: "Connection failed due to network error",
          });
          validForm = false;
          return;
        } else if ([401, 403].includes(err.response.status)) {
          setExpiredLinkOpen(true);
        } else {
          setInvalidLinkOpen(true);
        }
      })
      .finally(() => setVerifyLoading(false));
  }

  async function handleResetPassword(event) {
    let validForm = true;
    let errors = { ...defaults.resetPassword };
    const btn = event.target;

    for (let field in resetPwForm) {
      if (!resetPwForm[field]) {
        errors[field] = "Required field missing";
        validForm = false;
      }
    }
    setResetPwErr(errors);

    if (!validForm) {
      stopLoading(btn);
      shake(btn);
      return;
    }
    setLoading(btn);

    // encrypt the password
    const encryptedPassword = encryptWithPublicKey(resetPwForm.newPassword);
    const encryptedConfirmPassword = encryptWithPublicKey(
      resetPwForm.confirmNewPassword,
    );

    await axios
      .post(`${ServerUrl}/users/reset-password/forgot-reset`, {
        token: encodedToken,
        email: resetPwForm.email,
        password: encryptedPassword,
        confirmPassword: encryptedConfirmPassword,
      })
      .then((res) => {
        //user = res.data.data;
      })
      .catch((err) => {
        // check network error
        if (err.code === "ERR_NETWORK") {
          setResetPwErr({
            ...defaults.resetPassword,
            email: "Signin failed due to network error",
          });
          validForm = false;
          return;
        }
        const res = err.response;
        setResetPwErr({
          ...defaults.resetPassword,
          [res.data.data]: res.data.message,
        });
        validForm = false;
      });
    if (!validForm) {
      stopLoading(btn);
      shake(btn);
      return;
    }
    navigate("/home");
  }

  return (
    <OverlayScrollbarsComponent
      className="scroll-form"
      options={{
        scrollbars: {
          clickScroll: true,
        },
      }}
    >
      <Helmet>
        <title>Reset Password | Hazard Perception</title>
      </Helmet>
      <ScrollToError deps={[resetPwErr]} />
      <header className="main">Create New Password</header>
      <div className="input-col reset-pw-form">
        <p>
          Please ensure your the new password is strong and has not been used
          before. Your password must:
        </p>
        <ul>
          <li>be at least 8 characters long</li>
          <li>
            contain at least one uppercase letter, one lowercase letter, and one
            number
          </li>
          <li>
            only include certain special characters: <code>@$!%_?&</code>
          </li>
        </ul>
        <InputField
          id="email"
          label="Email"
          autoComplete="email"
          error={resetPwErr.email}
          value={resetPwForm.email}
          filters={emailFilters}
          readOnly
          disabled
        />
        <InputField
          type="password"
          id="newPassword"
          label="Password"
          autoComplete="new-password"
          error={resetPwErr.newPassword}
          value={resetPwForm.newPassword}
          onChange={formInput}
          disabled={!encodedToken}
        />
        <InputField
          type="password"
          id="confirmNewPassword"
          label="Confirm Password"
          autoComplete="new-password"
          error={resetPwErr.confirmNewPassword}
          value={resetPwForm.confirmNewPassword}
          onChange={formInput}
          disabled={!encodedToken}
        />
      </div>
      <Button
        type="submit"
        fontSize="1.4rem"
        variant={resetDisabled && "secondary"}
        label="Reset Password"
        onClick={handleResetPassword}
        disabled={resetDisabled}
        loading={verifyLoading}
      />
      <ResetPwModal
        title={invalidLinkOpen ? "Link Invalid" : "Link Expired"}
        state={[expiredLinkOpen || invalidLinkOpen]}
      >
        {invalidLinkOpen ? (
          <>
            Hmm, this link doesn't seem to work. If you still want to reset your
            password, you can request another link below.
          </>
        ) : (
          <>
            For security reasons, this link can no longer be used to reset your
            password. If you still want to reset your password, you can request
            another link below.
          </>
        )}
      </ResetPwModal>
    </OverlayScrollbarsComponent>
  );
}

export default ResetPassword;
