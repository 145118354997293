import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { pause } from "../Utils";
import { ModalContext } from "../contexts/Contexts";
import { Button } from "./Button";
import ForgotPasswordRequest from "./ForgotPasswordRequest";
import Modal, { focusFirstField, swapContent } from "./Modal";

/**
 * @param {{
 *   children: React.ReactNode;
 *   state: UseState<boolean>;
 *   title: string;
 * }} props
 */
const ResetPwModal = (props) => {
  const { children, state, title } = props;
  const [isOpen, setIsOpen] = state;

  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [resetPwOpen, setResetPwOpen] = useState(false);

  async function openForgotPassword(modalContent) {
    await swapContent(modalContent, async () => {
      await pause(100);
      setResetPwOpen(true);
      setEmail("");
      await pause(100);
    });
    await pause(200);
    focusFirstField(modalContent);
  }

  return (
    <Modal
      id="link-expired-modal"
      className="link-expired-modal"
      title={resetPwOpen ? "Password Reset" : title}
      contentClassName="link-expired-form"
      contentLabel="Link Expired Modal"
      isOpen={isOpen}
      hideCloseBtn
      focusOnOpen
    >
      {!resetPwOpen && (
        <IssueNote content={children} openForgotPW={openForgotPassword} />
      )}
      <ForgotPasswordRequest
        openState={[resetPwOpen, setResetPwOpen]}
        emailState={[email, setEmail]}
        showHeader={false}
        actionLabel="Resend Link"
        successLabel="Return to Home"
        successAction={async () => {
          setIsOpen(false);
          await pause(500);
          navigate("/home");
        }}
      />
    </Modal>
  );
};

/**
 * @param {{
 *   content: React.ReactNode;
 *   openForgotPW: function;
 * }} props
 */
const IssueNote = (props) => {
  const { content, openForgotPW } = props;
  const { contentElement } = useContext(ModalContext);

  return (
    <>
      <div className="input-col">
        <p className="reset-link-expired">{content}</p>
      </div>

      <Button
        label="Request Password Reset"
        onClick={() => openForgotPW(contentElement)}
        styles={{
          marginBottom: "1rem",
        }}
      />
    </>
  );
};

export default ResetPwModal;
