import { IconMailCheck } from "@tabler/icons-react";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { ServerUrl, emailFilters, pause } from "../Utils";
import { ModalContext } from "../contexts/Contexts";
import { Button, setLoading, shake, stopLoading } from "./Button";
import InputField from "./InputField";
import { ScrollToError, focusFirstField, swapContent } from "./Modal";

/**
 * @param {{
 *   openState: UseState<boolean>;
 *   emailState: UseState<string>;
 *   showHeader: boolean;
 *   actionLabel: string;
 *   successLabel: string;
 *   successAction: function;
 * }} props
 */
const ForgotPasswordRequest = (props) => {
  const {
    openState,
    emailState,
    showHeader = true,
    actionLabel = "Request Link",
    successLabel = "Return to Login",
    successAction,
  } = props;

  const [isOpen, setIsOpen] = openState;
  const [email, setEmail] = emailState;
  const { contentElement } = useContext(ModalContext);

  const [successfulSend, setSuccessfulSend] = useState(false);
  const [emailErr, setEmailErr] = useState("");

  useEffect(() => {
    if (!isOpen) {
      setEmailErr("");
      setSuccessfulSend(false);
    }
  }, [isOpen]);

  async function handleRequestPwReset(event) {
    event.preventDefault();

    let validForm = true;
    const btn = event.target;

    if (!email) {
      shake(btn);
      setEmailErr("Email must be provided");
      return;
    }
    setLoading(btn);

    await axios
      .post(`${ServerUrl}/users/reset-password/url`, {
        email: email,
      })
      .then(() => {
        swapContent(contentElement, async () => {
          await pause(100);
          setSuccessfulSend(true);
          await pause(100);
        });
        stopLoading(btn);
        setEmailErr("");
      })
      .catch((err) => {
        // check network error
        if (err.code === "ERR_NETWORK") {
          setEmailErr("Login failed due to network error");
          validForm = false;
          return;
        }
        const error = err.response.data;
        setEmailErr(error.message);
        validForm = false;
      });

    if (!validForm) {
      stopLoading(btn);
      shake(btn);
      return;
    }
  }

  async function handleReturn(event) {
    event.preventDefault();

    if (successfulSend && successAction) return successAction?.();

    await swapContent(contentElement, async () => {
      await pause(100);
      setIsOpen(false);
      await pause(100);
    });

    const pwField = contentElement.querySelector("#password");
    await pause(200);

    if (successfulSend) {
      pwField && pwField.focus();
    } else {
      focusFirstField(contentElement);
    }
  }

  return (
    isOpen && (
      <>
        <Helmet>
          <title>Request Password Reset</title>
        </Helmet>
        <ScrollToError deps={[emailErr]} />
        {successfulSend ? (
          <>
            <div className="forgot-pw-message">
              <header>
                <IconMailCheck width="1.9rem" height="1.9rem" />
                Check your inbox!
              </header>
              <p>
                We sent a link with instructions for resetting your account
                password to the following email:
              </p>
              <span className="email-given">{email}</span>
            </div>
            <span className="button-row">
              <Button label={successLabel} onClick={handleReturn} />
            </span>
          </>
        ) : (
          <>
            <div className="forgot-pw-message">
              {showHeader && <header>Forgot Your Password?</header>}
              <p>
                Enter the email address associated with your account and we will
                send you a link to reset your password.
              </p>
            </div>
            <div className="input-col">
              <InputField
                id="email"
                label="Email"
                autoComplete="email"
                error={emailErr}
                value={email}
                filters={emailFilters}
                onChange={(event) => setEmail(event.target.value)}
              />
            </div>
            <span className="button-row">
              <Button
                label="Cancel"
                variant="secondary"
                onClick={handleReturn}
              />
              <Button
                type="submit"
                label={actionLabel}
                onClick={handleRequestPwReset}
                styles={{ width: "100%" }}
              />
            </span>
          </>
        )}
      </>
    )
  );
};

export default ForgotPasswordRequest;
